.acfe-form-container {
  max-width: 1400px;
  margin: auto;
}

.acfe-form-body hr {
  border: 2px solid #fafafa;
  border-radius: 2px;
}

.afce-form-body-section {
  display: flex;
  flex-flow: row wrap;
  column-gap: 40px;
  margin-bottom: 40px;
}

.afce-form-body-section > * {
  flex: 1 1 26rem;
}

.acfe-form-field-details {
  color: #666666;
}

.acfe-form-field-details p {
  text-align: justify;
  line-height: 1.5;
  font-size: 0.925rem;
}

.acfe-form-field-details em {
}

.acfe-form-field-wrapper {
}

.acfe-form-field {
  display: flex;
  flex-flow: column nowrap;
  gap: 0.25rem;
  margin-top: 1.33em;
}

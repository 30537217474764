.mobile-filter-layout {
  display: none;
  padding: 10px 10px 0 10px;
}

.mobile-filter-container {
  display: none;
  width: 100%;
  flex-flow: column nowrap;
  justify-self: start;
  gap: 10px;
}

.filter-button {
  padding: 16px 26px;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  border-radius: 12px;
  border: none;
  text-transform: capitalize;
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 1.1rem;
  background-color: white;
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  z-index: 2;
}

.filter-button:hover {
  box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.15s ease-in-out;
}

.filter-button-icon {
  font-size: 1.5rem;
  vertical-align: bottom;
  transition: all 0.2s ease-out;
}

.icon-active {
  transform: rotateZ(-180deg);
}

.sessions-table {
  padding: 0;
}

.sessions-table li {
  border-radius: 0.5rem;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.sessions-table .table-row {
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}

.sessions-table .col {
  white-space: nowrap;
}

.sessions-table .col-1 {
  flex-basis: 45%;
}

.sessions-table .col-2 {
  flex-basis: 25%;
  text-align: center;
}

.sessions-table .col-3 {
  flex-basis: 15%;
}

@media all and (max-width: 798px) {
  .sessions-table li {
    display: block;
  }

  .sessions-table .col {
    flex-basis: 100%;

    display: flex;
    padding: 5px 0;
    justify-content: center;
  }

  /* .sessions-table .col:before {
    color: #6c7a89;
    padding-right: 10px;
    content: attr(data-label);
    flex-basis: 40%;
    text-align: right;
  } */
}

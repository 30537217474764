.loader-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 105px;
  height: 105px;
  background: transparent;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  text-align: center;
  line-height: 105px;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.loader-ring:before {
  content: "";
  position: absolute;
  top: -2.5px;
  left: -2.5px;
  width: 105%;
  height: 105%;
  border: 2px solid transparent;
  border-top: 2px solid currentColor;
  border-right: 2px solid currentColor;
  border-radius: 50%;
  animation: loader-ring 1.25s linear infinite;
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

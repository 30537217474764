.entry-details-title {
  margin-top: 0;
  font-size: min(2.5rem, 8vw);
  font-weight: 600;

  position: relative;
}

.entry-details-title[data-free="true"]::after {
  content: "FREE";
  position: relative;
  font-size: clamp(0.65rem, 2vw, 0.875rem);
  margin-left: 0.75rem;
  vertical-align: 50%;
  color: currentColor;
  border: 1px solid currentColor;
  padding: 4px 10px;
  border-radius: 1rem;
  box-sizing: border-box;
}

.entry-long-description {
  line-height: 1.5;
  margin: 30px 0;
}
.entry-long-description * {
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
}

.entry-info-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  gap: 30px 20px;
  margin: 30px 0;
}

.entry-info-container > * {
  white-space: nowrap;
}

.entry-info-container .entry-info-wrapper {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.entry-info-container .entry-info-wrapper .entry-info-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  text-transform: capitalize;
}

.entry-info-container .entry-info-wrapper .entry-info-header * {
  margin: 0;
  font-weight: 600;
}

.entry-info-container .entry-info-wrapper .entry-info {
  margin: 0;
  padding-left: 3px;
  font-weight: 500;
}

.details-container .cost-info {
  margin: 1.75rem 0;
}

.details-container .cost-info .cost-info-header {
  font-weight: 600;
  font-size: 1.2rem;
}

.details-container .cost-info p {
  margin: 10px;
  font-weight: 500;
}

.details-container .btn-group {
  margin: 30px 0;
}

.details-container .btn-group .register-btn {
  font-weight: 500;
}

.details-container .btn-group .btn-details {
  margin-top: 6px;
  font-weight: 500;
}

.cost-table {
  width: 100%;
  max-width: 30rem;
  border-collapse: collapse;
  border-radius: 0.4em;
  margin: 30px 0;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;

  overflow: hidden;
}

.cost-table th,
.cost-table td {
  padding: 8px;
  text-align: left;
}

.cost-table th {
  font-weight: 500;
}

.cost-table tr:nth-child(odd) {
  background-color: #f2f2f2;
}

.cost-table tr:nth-child(even) {
  background-color: #f7f7f7;
}

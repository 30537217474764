.modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000b3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  z-index: 1000;
}

.modal {
  display: inherit;
  max-height: 100vh;
}

.modal > * {
  overflow-y: overlay;
}

.modal-close-btn {
  position: absolute;
  top: calc(10px + 4vh);
  right: 32px;
  font-size: 1.4rem;
  opacity: 0.5;
  cursor: pointer;
}

.register-form {
  max-width: 38.75rem;
  padding: min(2rem, 1.5vw);
  margin: 20px;
  margin-top: min(50px, 4vh);

  overflow-y: auto;
}

.register-form-container {
  display: flex;
  flex-flow: row wrap;
  gap: 1.4rem;
}

.register-form-container > div {
  flex: 0 0 100%;
}

.register-form-container .register-form-button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;

  flex: 0 0 100%;
}

.register-form-container .register-member-link {
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
}

.register-form-container .privacy-policy-container {
  margin-left: auto;
  color: #383e4b;
  font-size: 0.9rem;
  margin-bottom: -10px;
}

:root {
  --font-color: #685e5e;
}

html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  overflow: overlay;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.App {
  position: relative;
  background-color: #fbfcfe;
}

.sub-heading {
  font-weight: 500;
  color: var(--font-color);
}

.back-btn {
  display: flex;
  flex-basis: 100%;
  align-items: center;
  gap: 0.4rem;
  line-height: 1;
  color: var(--font-color);
  margin-bottom: 10px;

  cursor: pointer;
}

.faded-hr {
  margin-block: 3rem 2rem;
  border: 0;
  height: 1px;
  opacity: 0.4;
}

@media only screen and (max-width: 1180px) {
  .home-container {
    /* min-height: 100vh; */
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-auto-flow: column;
    gap: 20px;
  }
  .home-side-bar {
    display: none;
  }
  .mobile-filter-layout {
    display: grid;
    gap: 1.25rem;
    grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  }
  .mobile-filter-container {
    display: flex;
  }
  .courses-section {
    min-height: 100%;
    padding: 0 10px 10px 10px;
  }
}

@media only screen and (max-width: 420px) {
  .mobile-filter-container {
    width: 100%;
  }
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb:horizontal {
  background-color: rgba(100, 100, 100, 0.4);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(100, 100, 100, 0.4);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgb(100, 100, 100);
}

::-webkit-scrollbar-corner {
  display: none;
}

.entry-card {
  color: var(--font-color);
  height: 600px;
  display: flex;
  justify-content: center;
}

.entry-card .entry-tile {
  width: 100%;
  overflow: hidden;
  transition: transform 200ms ease-in-out !important;
}

.entry-card .entry-tile:hover {
  transform: scale(1.025);
}

.entry-card .divider {
  height: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr auto;
}

.entry-card .img-container {
  position: relative;
  background-color: #f1f1f1;
  overflow: hidden;
}

.entry-card .category {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 4px 12px;
  border-radius: 0.5em;
  z-index: 2;
}

.entry-card .website-image {
  position: relative;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;

  transition: 200ms all ease-in-out;
}

.entry-card:hover .website-image {
  transform: scale(1.025);
}

.entry-card .loader-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.entry-card .horizontal-loader {
  font-size: x-small;
}

.entry-card .entry-tile-body {
  position: relative;
  padding-top: 0;
}

.entry-card .entry-tile-body::before {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  right: 0;
  height: 14px;
  border-radius: 12px 12px 0 0;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.1) 0px -2px 4px 0px,
    rgba(0, 0, 0, 0.1) 0px -2px 1px 0px;
}

.entry-card .entry-tile-body * {
  margin: 0;
  line-height: 1.5;
}

.entry-card .entry-tile-body .title {
  font-size: 1.4rem;
  font-weight: 500;
  margin-bottom: 14px;
  text-align: center;

  /*for text ellipsis*/
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
}

.entry-card .entry-tile-body .description {
  font-weight: 400;
  margin-bottom: 12px;
  text-align: center;
  line-height: 1.2;

  /*for text ellipsis*/
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
}

.entry-card .entry-tile-body .info-container {
  margin-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.925rem;
  font-weight: 400;
  line-height: 1.2;
  color: #494a50;
  opacity: 0.8;

  transition: all 200ms ease-in-out;
}

.entry-card .entry-tile-body .info-container:hover {
  opacity: 1;
}

.entry-card .entry-tile-body .cost {
  /*for text ellipsis*/
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
}

.entry-card .entry-tile-body .register-btn {
  display: block;
  font-weight: 500;
  margin: 18px auto 5px auto;

  transition: 200ms transform ease-in-out;
}

.entry-card:hover .register-btn {
  transform: scale(1.025);
}

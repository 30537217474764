.details-container {
  color: var(--font-color);
  padding: 10px;
}

.details-container h2 {
  font-weight: 500;
}

.course-wrapper {
  display: block;
  margin-top: 1.4rem;
  margin-bottom: 2rem;
}

@media (min-width: 900px) {
  .course-wrapper {
    display: flex;
    gap: 1.5rem;
  }
  .entry-details-container,
  .details-side-container {
    flex-basis: 50%;
  }
}

.details-side-container {
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 500px;
  padding-top: 35%;
  border-radius: 20px;
  overflow: hidden;

  background: #f5f4f4 center/ cover no-repeat;
}

.details-side-container .horizontal-loader {
  position: relative;
  font-size: 10px;
}

.details-image {
  width: 100%;
  display: block;
  object-fit: cover;
}

.details-container .contact-details {
  display: flex;
  gap: 0.875rem;
  flex-direction: column;
  padding: 0 1rem;
}

.details-sessions-container {
  margin: 1.25rem 0;
}

.page-block {
  margin: 1.75rem 0;
}

.page-block .block-content {
  line-height: 1.5;
  text-align: justify;
  margin: 1rem;
}

.home-container {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: start;
}

.courses-section {
  position: relative;
  min-height: 300px;
  height: 100%;
  width: 100%;
  padding: 20px 10px 10px 0;
}

.courses-section .info-btn {
  display: block;
  margin: 40px auto 20px auto;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.courses-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275px, 1fr));
  gap: 1.25rem;
}

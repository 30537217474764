.home-side-bar {
  width: auto;
  padding: 0.4rem 1.25rem 0.5rem 0.25rem;
  margin-top: -0.4rem;
  overflow-y: auto;

  position: sticky;
  top: calc(20px - 0.4rem);
  display: flex;
  flex-flow: column;
  gap: 16px;
}

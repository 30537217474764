.payment-page {
  width: fit-content;
  margin: auto;
  min-height: 100vh;
  padding: 10px 0;
}

.payment-container {
  max-width: 780px;
  padding: 20px;
  margin: 20px 0;
}

.body-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
  align-items: center;
}

.body-wrapper > .field-wrapper {
  flex: 1 1 16rem;
}

.btn-wrapper {
  width: 100%;
}

.surcharge-info {
  font-style: italic;
  color: #6b6b6b;
  font-size: small;
  opacity: 0.75;
}

@media only screen and (max-width: 400px) {
  .payment-container {
    padding: 5px !important;
    margin: 5px auto;
  }
}

.pin-payment-logo {
  max-width: fit-content;
  font-size: 0.75rem;
  border: 1px solid var(--font-color);
  border-radius: 4px;
  padding: 10px;
  margin: auto;
  opacity: 0.8;
}
